/* errors  css */
.errors{
    font-weight: normal;
    font-size: 12px;
    color: red;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  pointer-events: auto! important;
  cursor: not-allowed! important;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 1.5em;
  border-top: 1px solid lightgray;
  /* max-height: 0; */
  overflow: hidden;
  overflow-y: auto;
  animation: enlarge 0.25s forwards;
  position: absolute;
  background-color: white;
  padding: 0px 20px 20px 20px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}

.list li {
  margin-right: -1.5em;
  margin-left: -1.5em;
  padding: 0 1.5em;
  line-height: 1.5;
  cursor: pointer;
}
.list li:hover {
  background: whitesmoke;
}

/* disbled checkbox color property for Application malls selection */
/* input[type="checkbox"]:disabled + span.material-control-indicator {
  background: #dddddd;
} */

/* disbled checkbox color property for Application malls selection */
.disabledClass{
  background: #dddddd;
}
[type="search"]{
  letter-spacing: -0.8px !important;
}

.modal-dialog {
  margin: 0.75rem auto !important;
}
.disabled__color{
  background-color: #e9ecef;
  opacity: 1;
}

.backLink{
  position: absolute ;
  margin-top: 3rem ;
  left:49%; 
}


.rmsc {
  --rmsc-main: #4285f4!important;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ced4da!important;
  --rmsc-gray: #282727!important;
  --rmsc-bg: #F8F8F8!important;
  --rmsc-p: 10px!important;
  --rmsc-radius: 4px!important;
  --rmsc-h: 32px!important;
  font-weight: 500;
  font-size: 12px;
  height: 32px;
  padding-left: 0px!important;
}

.rmsc * {
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.rmsc .gray {
  color: var(--rmsc-gray);
}

.rmsc .dropdown-content {
  position: absolute;
  z-index: 99999;
  top: 100%;
  width: 100%;
  padding-top: 8px;
}

.rmsc .dropdown-content .panel-content {
  overflow: hidden;
  border-radius: var(--rmsc-radius);
  background: var(--rmsc-bg);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
}

.rmsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
}

.rmsc .dropdown-container[aria-disabled="true"]:focus-within {
  box-shadow: var(--rmsc-gray) 0 0 0 1px;
  border-color: var(--rmsc-gray);
}

.rmsc .dropdown-container:focus-within {
  box-shadow: var(--rmsc-main) 0 0 0 1px;
  border-color: var(--rmsc-main);
}

.rmsc .dropdown-heading {
  position: relative;
  padding: 0 var(--rmsc-p);
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--rmsc-h);
  cursor: default;
  outline: 0;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1;
}

.rmsc .clear-selected-button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: flex;
}

.rmsc .options {
  max-height: 260px;
  overflow-y: auto;
  margin: 0;
  padding-left: 0;
}

.rmsc .options li {
  list-style: none;
  margin: 0;
}

.rmsc .select-item {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: var(--rmsc-p);
  outline-offset: -1px;
  outline-color: var(--rmsc-primary);
}

.rmsc .select-item:hover {
  background: var(--rmsc-hover);
}

.rmsc .select-item.selected {
  background: var(--rmsc-selected);
}

.rmsc .no-options {
  padding: var(--rmsc-p);
  text-align: center;
  color: var(--rmsc-gray);
}

.rmsc .search {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--rmsc-border);
}

.rmsc .search input {
  background: none;
  height: var(--rmsc-h);
  padding: 0 var(--rmsc-p);
  width: 100%;
  outline: 0;
  border: 0;
  font-size: 1em;
}

.rmsc .search input:focus {
  background: var(--rmsc-hover);
}

.rmsc .search-clear-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  border: 0;
  padding: 0 calc(var(--rmsc-p) / 2);
}

.rmsc .search-clear-button [hidden] {
  display: none;
}

.rmsc .item-renderer {
  display: flex;
  align-items: center !important;
}

.rmsc .item-renderer input {
  margin: 0;
  margin-right: 5px;
}

.rmsc .item-renderer.disabled {
  opacity: 0.5;
}

.rmsc .spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
}

.rmsc .spinner .path {
  stroke: var(--rmsc-border);
  stroke-width: 4px;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.mso .dropdown-container {
  border: 0;
  display: inline-block;
  width: 100%;
}

.mso .dropdown-container:focus-within {
  box-shadow: none;
  border-color: transparent;
}

.mso .dropdown-heading {
  padding: 0;
  height: auto;
}

.mso .dropdown-heading-dropdown-arrow {
  display: none;
}

.tms-pdfviewer input,
.tms-pdfviewer button {
  font: inherit;
}

.tms-pdfviewer header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.tms-pdfviewer header h1 {
  font-size: inherit;
  margin: 0;
}

.tms-pdfviewer-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tms-pdfviewer-cd {
  margin: 1em 0;
}

.tms-pdfviewer-cd .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tms-pdfviewer-cd .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.tms-pdfviewer-cd .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.tms-pdfviewer-cd .react-pdf__message {
  padding: 20px;
}

#faq4 input[type="radio"]{
  left: 0px!important;
  top: 0px!important;
  width: 70px;
}

#progressbar li.active::after {
  background: #63D170;
  border: 1px solid #63D170;
}

#progressbar li.active1::after {
  background: #E85D3A;
  border: 1px solid #E85D3A;
}

@media (max-width: 767.98px){
.notifidrop {
  right: 0px;
  margin-right: -135px !important;
  float: right;
}
.applitable .btn-add {
  float: right;
  margin-top: 40px;
  font-size: 10px;
}
}

.signinform-div {
  background: #ffffff;
}

.applicationsList #progressbar li {
  width: 30%!important;
}
/*
.form-control:disabled, .form-control[readonly], .dropdown-container[aria-disabled="true"] {
  background: none!important;
  border: none!important;
  border-bottom: 1px solid black!important;
}

.dropdown-container[aria-disabled="true"] .clear-selected-button, .dropdown-container[aria-disabled="true"] .dropdown-heading-dropdown-arrow {
  display: none!important;
}*/


.rmsc .dropdown-container[aria-disabled="true"]{
  background-color: #e9ecef;
  font-weight: 500;
}

.rmsc .dropdown-container[aria-disabled="true"] .clear-selected-button{
  display:none;
}

.evnts .file-upload-image, .offrs .file-upload-image{
  max-height: 140px;
}

.reject-reason {
  font-size: 12px !important;
  text-align: center;
  color: #E85D3A !important;
  margin-bottom: 0px !important;
  margin-top: -20px;
}